export const prepareDataCliente = (dataCliente = []) => {

    return dataCliente.map((el) => ({
        cliente_id: el.id.toString(),
        cliente_ruc: el.ruc,
        cliente_razon_social: el.razon_social,
        cliente_nombre_comercial: el.nombre_comercial,
        cliente_direccion_legal: el.direccion_legal,
        cliente_pais: el.pais_organizacion,
        cliente_direccion_completa: el.full_direction,
        cliente_departamento: el.departamento_organizacion,
        cliente_provincia: el.provincia_organizacion,
        cliente_distrito: el.distrito_organizacion,
        cliente_miembros: el.members.map((member) => ({
            sede_id: member.id.toString(),
            sede_nombre: member.nombre_departamento,
            sede_direccion: member.direccion_departamento,
            sede_pais: member.pais_departamento,
            sede_departamento: member.departamento_departamento,
            sede_provincia: member.provincia_departamento,
            sede_distrito: member.distrito_departamento,
            sede_contacto:member.contactos,
            sede_miembros: member.members.map((area) => ({
                area_id: area.id.toString(),
                area_nombre: `${area.nombre_area}-${area.ubicacion.piso}-${area.ubicacion.sala}`
            }))
        }))
    }))

}


