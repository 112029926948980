import { types } from "src/types/types"


const initialState = {
    dataCalibracion: [],
    activeCalibracion: null
}

export const calibracionReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.calibracionLoaded:
            return { ...state, dataCalibracion: action.payload }
        case types.calibracionAddNew:
            return { ...state, dataCalibracion: [...state.dataCalibracion, action.payload] }
        case types.calibracionSetActive:
            return { ...state, activeCalibracion: action.payload }
        case types.calibracionUpdate:
            return { ...state, dataCalibracion: state.dataCalibracion.map((el) => el.id === action.payload.id ? { ...action.payload } : el) }
        case types.clearActiveCalibracion:
            return {
                ...state,
                activeCalibracion: null,
            };
        case types.calibracionDeleted:
            return {
                ...state,
                dataCalibracion: state.dataCalibracion.filter((el) =>
                    el.id !== action.payload
                ),
            };
        default:
            return state
    }
}
