import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import { Provider } from 'react-redux'
import store from './store'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp'; 
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
mapboxgl.accessToken = 'pk.eyJ1IjoiZGF2aWRhbGV4ZCIsImEiOiJjbGRkcjNpcjkwNGI1M3Zycjloc28xMHR4In0.Zpa5m9IdJXCKE0d_2c-8Zw';

if (!navigator.geolocation) {
  alert('Tu navegador no tiene opcion de Geolocalizacion')
  throw new Error('Tu navegador no tiene opcion de Geolocalizacion')
}

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <App />
  </Provider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();