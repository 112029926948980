import { types } from "src/types/types"


const initialState = {
    dataSistema: [],
    activeSistema: null
}

export const sistemaReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.sistemaLoaded:
            return { ...state, dataSistema: action.payload }
        case types.sistemaAddNew:
            return { ...state, dataSistema: [...state.dataSistema, action.payload] }
        case types.sistemaSetActive:
            return { ...state, activeSistema: action.payload }
        case types.sistemaUpdate:
            return { ...state, dataSistema: state.dataSistema.map((el) => el.id === action.payload.id ? { ...action.payload } : el) }
        case types.clearActiveSistema:
            return {
                ...state,
                activeSistema: null,
            };
        case types.sistemaDeleted:
            return {
                ...state,
                dataSistema: state.dataSistema.filter((el) =>
                    el.id !== action.payload
                ),
            };
        default:
            return state
    }
}


