import { types } from "src/types/types";

const initialState = {
    sidebarShow: true,
    sidebarUnfoldable: true,
    loadingButton:false,
    modalOpen: false,
    loading: false,
    loadingRoute: false
}
export const uiReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.uiChangeStateSidebar:
            return { ...state, ...action.payload }
        case types.uiStartLoading:
            return {
                ...state,
                loading: true,
            }
        case types.uiFinishLoading:
            return {
                ...state,
                loading: false,
            }

        case types.uiStartDisabled:
            return {
                ...state,
                loadingButton: true,
            }
        case types.uiFinishDisabled:
            return {
                ...state,
                loadingButton: false,
            }
        case types.uiOpenModal:
            return { ...state, modalOpen: true };
        case types.uiCloseModal:
            return { ...state, modalOpen: false };
        case types.uiStartLoadingRoutes:
            return { ...state, loadingRoute: true };
        case types.uiFinishLoadingRoutes:
            return { ...state, loadingRoute: false };
        default:
            return state
    }
}
