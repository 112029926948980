import { types } from 'src/types/types'

const initialState = {
  areaData: [],
  activeArea: null
}
export const areaReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.areaLoaded:
      return {
        ...state,
        areaData: action.payload,
      }
    case types.areaAddNew:
      return { ...state, areaData: [...state.areaData, action.payload] }
    case types.areaUpdate:
      return { ...state, areaData: state.areaData.map((el) => el.id === action.payload.id ? { ...action.payload } : el) }
    case types.areaSetActive:
      return { ...state, activeArea: action.payload }
    case types.ClearActiveArea:
      return { ...state, activeArea: null }
    case types.areaDeleted:
      return {
        ...state,
        areaData: state.areaData.filter((el) =>
          el.id !== action.payload
        ),
      };

    default:
      return state
  }
}
