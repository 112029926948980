// import { createStore } from 'redux'
import { configureStore,createImmutableStateInvariantMiddleware,getDefaultMiddleware  } from '@reduxjs/toolkit'
import { areaReducer } from './reducers/areaReducer'
import { authReducer } from './reducers/authReducer'
import { calculateReducer } from './reducers/calculateReducer'
import { calibracionReducer } from './reducers/calibracionReducer'
import { componenteReducer } from './reducers/componenteReducer'
import { contactoReducer } from './reducers/contactoReducer'
import { medidorReducer } from './reducers/medidorReducer'
import { navReducer } from './reducers/navReducer'
import { organizacionReducer } from './reducers/organizacionReducer'
import { registroReducer } from './reducers/registroReducer'
import { reportReducer } from './reducers/reportReducer'
import { sedesReducer } from './reducers/sedesReducer'
import { sesionesReducer } from './reducers/sesionesReducer'
import { sistemaReducer } from './reducers/sistemaReducer'
import { timeReducer } from './reducers/timeReducer'
import { uiReducer } from './reducers/uiReducer'


const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false, // desactivar la verificación de serialización para evitar warnings en consola
}).filter(
  (middleware) => middleware !== createImmutableStateInvariantMiddleware,
);


const store = configureStore({
  reducer: {
    ui:uiReducer,
    auth:authReducer,
    nav:navReducer,
    registro:registroReducer,
    calculate:calculateReducer,
    organizacion: organizacionReducer,
    sedes:sedesReducer,
    contacto:contactoReducer,
    area:areaReducer,
    calibracion:calibracionReducer,
    medidor:medidorReducer,
    sistema:sistemaReducer,
    componente:componenteReducer,
    report: reportReducer,
    sesiones:sesionesReducer,
    time:timeReducer,
    middleware: customizedMiddleware,

  },
})
export default store