
export const prepareInitialValuesCheckbox = (option) => {
  return option.map(el => ({ ...el, valor: false }))
}


export const prepareValuesImages = (base64Image) => {
  const file = new File([convertBase64ToBlob(base64Image)], 'image.jpg', { type: 'image/jpeg' });
  return file
}

const convertBase64ToBlob = (base64String) => {

  const byteString = atob(base64String.split(',')[1]);
  const mimeString = base64String.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}



export const prepareResultadosPruebas = (calculateData) => {

  let resultados_pruebas = calculateData.map((el) => [
    {
      resultados: [
        {
          esElementoTabla: el.esElementoTabla,
          idOperacion: el.id_operacion,
          prueba: el.prueba,
          resultados: {
            resultado: {
              condicion: el.condicion,
              data: el.resultado,
              estado: el.estado,
              tolerancia: el.tolerancia,
            },
          },
          seccion: el.seccion,
          variables: el.variables
        },
      ],
    },
    {
      variables: el.variables.map((variable) => ({
        nombre: variable.nombre,
        valor: [variable.valor],
      })),
    },
  ])

  return resultados_pruebas
}
