import { types } from 'src/types/types'

const initialState = {
  calculateData: [],
  CalculateVariablesData: [],
  datafaild: [],
  checkedAll: [],
  inputData: null,
  variableData: [],
  selectedItems: [],
  selectedGeneral: null,
  statusChecked: false,
  statuscheckedGeneral: {},
  dataResult: [{ name: '', value: '' }]
}
export const calculateReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.calculateLoaded:
      return {
        ...state,
        calculateData: action.payload.formulas_all,
        CalculateVariablesData: action.payload.variables_form
      }
    case types.updateCalculateData:
      const { value, checked } = action.payload
      let newUpdateCalculateData = state.calculateData.map(el => el.id_operacion.toString() === value ? { ...el, condicion: checked } : el)
      let nameSeccion = newUpdateCalculateData.find(el => el.id_operacion.toString() === value).seccion
      let itemSeccion = newUpdateCalculateData.filter(el => el.seccion === nameSeccion)
      let isFullSeccion = itemSeccion.every(val => val.condicion === true);

      return {
        ...state,
        calculateData: newUpdateCalculateData,
        selectedItems: newUpdateCalculateData.filter(el => el.id_operacion.toString() === value),
        statusChecked: checked,
        statuscheckedGeneral: { ...state.statuscheckedGeneral, [nameSeccion]: isFullSeccion },

      }
    case types.calculateClear:
      return {
        ...state,
        calculateData: initialState.calculateData
      }
    case types.calculateFaildTest:
      //
      //const { element, checked } = action.payload
      //{ element:data, checked }

      //   {
      //     element: {
      //         id: data.operacionID,
      //         name: data.operacionNombre,
      //         value: ''
      //     }, checked
      // }


      return {
        ...state,
        // datafaild: checked ? [...state.datafaild, element] : state.datafaild.filter((item) => item.id !== element.id),
        // statusChecked: checked,
        // selectedItems: [element]
      }
    case types.calculateCheckAll:
      // const { item, isChecked, valueGeneral } = action.payload
      const { checkedGeneral, valueGeneral } = action.payload
      //? { ...el, condicion: checkedGeneral } : el
      let elementosSeccion = state.calculateData.filter(el => el.seccion === valueGeneral)
      let elemetosActualizados = elementosSeccion.map(el => ({ ...el, condicion: checkedGeneral }))
      const newArr = state.calculateData.map(obj => {
        const objToUpdate = elemetosActualizados.find(o => o.id_operacion === obj.id_operacion);
        if (objToUpdate) {
          return objToUpdate;
        } else {
          return obj;
        }
      })

      return {
        ...state,
        selectedItems: elemetosActualizados,
        calculateData: newArr,
        statusChecked: checkedGeneral,
        statuscheckedGeneral: { ...state.statuscheckedGeneral, [valueGeneral]: checkedGeneral },
      }
    case types.AddNewFailTest:
      let secciones = action.payload.reduce((acc, obj) => {
        if (!acc.includes(obj.seccion)) {
          acc.push(obj.seccion);
        }
        return acc;
      }, []);

      let propertyAllObj = secciones.reduce((acc, seccion) => {
        const valoresCondicion = action.payload.filter(item => item.seccion === seccion).map(item => item.condicion);
        const estado = valoresCondicion.every(valor => valor === true);
        acc[seccion] = estado;
        return acc;
      }, {});

      return {
        ...state, calculateData: action.payload,
        statuscheckedGeneral: { ...state.statuscheckedGeneral, ...propertyAllObj },
      }

    case types.setInputObservacion:
      const { id_operacion, resultado } = action.payload
      // console.log(resultado)
      return {
        ...state,
        calculateData: state.calculateData.map(el => el.id_operacion.toString() === id_operacion ? { ...el, resultado: [{ ...el.resultado[0], resultado: resultado, }] } : el)
        , selectedItems: state.selectedItems.map(el => el.id_operacion.toString() === id_operacion.toString() ? {
          ...el, resultado: [{
            ...el.resultado[0], resultado: resultado,
          }]
        } : el),
        //dataResult: updateResult
      }
    case types.clearSelectedCombo:
      return {
        ...state,
        calculateData: [],
        selectedItems: [],
        statusChecked: false,
        statuscheckedGeneral:{}
        // selectedGeneral: null,
      }
    case types.variableInputLoaded:
      return {
        ...state,
        inputData: action.payload,
      }
    case types.updateInput:
      return {
        ...state,
        inputData: { ...state.inputData, ...action.payload }
      }
    case types.variableLoaded:
      return {
        ...state,
        variableData: action.payload,
      }
    default:
      return state
  }
}
