import { fetchArchivoConToken, fetchConToken, fetchURLConToken } from "src/helper/fetch";
import { prepareResultadosPruebas } from "src/helper/helperRegister";
import { types } from "src/types/types";
import Swal from "sweetalert2";
import { finishDisabledButton, finishLoading, startDisabledButton, startLoading } from "./ui";
import { prepareDataReport } from "src/helper/helperDevices";


export const reportStartLoading = ({ page = "1", take = "5", filters = [] }) => {
    return async (dispatch) => {
        try {
            dispatch(startLoading())
            let url = `v2/Report/ReportesCustomizeTable/?page=${page}&page_size=${take}`
            //filtering
            if (filters.length) {
                const filter = filters.map(filter => {
                    if (filter.id === "id") {

                        return isNaN(parseFloat(filter.value.trim())) ? `&${filter.id}=` : `&${filter.id}=${filter.value.trim()}`;
                    }

                    if (filter.id === "created_at" || filter.id === "fecha_control_calidad" || filter.id === "certificado") {
                        const formatDate = /^\d{4}-\d{2}-\d{2}$/;
                        return (!filter.value.trim().match(formatDate)) ? `&${filter.id}=` : `&${filter.id}=${filter.value.trim()}`;
                    }
                    return `&${filter.id}=${filter.value.trim()}`
                })
                url += filter.join('')

            }
            const body = await fetchConToken(url);
            if (!body.ok) {
                dispatch(reportLoaded({
                    page_current: -1,
                    page_total: 0,
                    total: 0,
                    data: []
                }));
                dispatch(finishLoading())
            } else {
                const resp = await body.json();
                dispatch(reportLoaded(resp));
                dispatch(finishLoading())
            }

        } catch (error) {
            dispatch(finishLoading())
            console.log(error)
        }

    }
}

export const addNewReport = (data) => ({
    type: types.addReport,
    payload: data
})


export const statisticalStartLoading = () => {
    return async (dispatch) => {
        try {
            dispatch(startLoading())
            const resp = await fetchConToken('v2/Report/ReportFormats');
            const body = await resp.json();

            if (body?.length > 0) {
                dispatch(statisticalLoaded(body));
            }
            dispatch(finishLoading())
        } catch (error) {
            dispatch(finishLoading())
            console.log(error)
        }

    }
}


const statisticalLoaded = (reports) => ({
    type: types.statisticalLoaded,
    payload: reports
})




export const filterDataReport = (data) => ({
    type: types.filterReport,
    payload: data
})

export const startCreateCertificate = (idReport) => {
    return async (dispatch) => {
        try {
            dispatch(startDisabledButton())
            const resp = await fetchConToken('v1/CertificadoCreateFormats/', { InformeID: idReport }, 'POST');
            const body = await resp.json();
            if (body.id) {
                console.log(body)
                if (body.detalles) {
                    dispatch(certificateUpdate({
                        prefijo_id: body.prefijo_id,
                        certificado_url: {
                            message: "Certificado Aprobado",
                            data: body.url,
                            status: true,
                            fecha_emision: body.updated_at
                        }
                        , id: body.id
                    }))
                } else {
                    dispatch(certificateUpdate({
                        prefijo_id: body.prefijo_id,
                        certificado_url: {
                            message: "Certificado Desaprobado",
                            data: body.url,
                            status: true,
                            fecha_emision: body.updated_at
                        }
                        , id: body.id
                    }))
                }
                Swal.fire({
                    icon: 'success',
                    title: 'Certificado Evaluado',
                    text: `Reporte Evaluado con id ${body.prefijo_id}`,
                })

            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: `Certificado no Evaluado`,
                })
            }
            dispatch(finishDisabledButton())
        }
        catch (error) {
            dispatch(finishDisabledButton())
            console.log(error)
        }

    }

}

const certificateUpdate = (certificado) => ({
    type: types.certificateUpdate,
    payload: certificado
})
const reportLoaded = (reports) => ({
    type: types.reportsLoaded,
    payload: reports
})
export const startReportActive = (id) => {
    return async (dispatch) => {
        try {
            dispatch(startLoading())
            const resp = await fetchConToken(`v2/Report/ReportFormats/${id}/`);
            const body = await resp.json();
            if (body?.id) {
                const data = prepareDataReport(body)

                dispatch(reportSetActive(data))

            }
            dispatch(finishLoading())

        }
        catch (error) {
            console.log(error)
        }

    }
}

export const reportSetActive = (data) => ({
    type: types.reportSetActive,
    payload: data,
})

export const startReportClienteUpdate = (cliente) => {
    return async (dispatch) => {

        try {
            const resp = await fetchConToken(`v1/ReportClienteFormats/${cliente.id}/update/`, cliente, 'PATCH');
            const body = await resp.json();
            if (body?.id) {
                Swal.fire({
                    icon: 'success',
                    title: 'Actualizacion correcta',
                    text: `Dato Actualizado`,
                })
                dispatch(reportClienteUpdate(body))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: `Dato no Actualizado`,
                })
            }
        }
        catch (error) {
            console.log(error)
        }

    }
};

export const startupdateImagesCSReport = (idReport, dataImages) => {
    return async (dispatch) => {
        let imagesToSend = new FormData()
        dataImages.map((image) => {
            if (image.id !== 4) {
                imagesToSend.append(`title_image_${image.id}`, image.name)
            }
            if (image.archive) {
                if (image.archive === 'delete') {
                    if (image.id === 4) {
                        imagesToSend.append(`image_extra`, '')
                    } else {
                        imagesToSend.append(`image_${image.id}`, '')
                    }

                } else {
                    if (image.id === 4) {
                        imagesToSend.append(`image_extra`, image.archive)
                    } else {
                        imagesToSend.append(`image_${image.id}`, image.archive)
                    }

                }
            }

        })

        try {
            //imagesToSend
            const resp = await fetchArchivoConToken(`v1/ReportGaleriaFormats/${idReport}/update/`, imagesToSend, 'PATCH');
            const body = await resp.json();
            console.log(body)
            if (body) {
                const parseImageVariables = {
                    title_image_1: body.title_image_1 ? body.title_image_1 : "",
                    title_image_2: body.title_image_2 ? body.title_image_2 : "",
                    title_image_3: body.title_image_3 ? body.title_image_3 : "",
                    image_1: body.image_1 ? body.image_1 : "",
                    image_2: body.image_2 ? body.image_2 : "",
                    image_3: body.image_3 ? body.image_3 : "",
                    image_extra: body.image_extra ? body.image_extra : "",
                }
                Swal.fire({
                    icon: 'success',
                    title: 'Actualizacion correcta',
                    text: `Dato Actualizado`,
                })
                dispatch(updateImagesCSReport({
                    infoImages: parseImageVariables, idReport
                }))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: `Dato no Actualizado`,
                })
            }
        }
        catch (error) {
            console.log(error)
        }
    }

}

const updateImagesCSReport = (data) => ({
    type: types.reportImagesCSUpdate,
    payload: data,
});




///

export const startupdateImageQrReport = (idReport, idCertificate, dataQr) => {
    return async (dispatch) => {
        const imageQrToSend = new FormData()
        imageQrToSend.append(`certificado_qr`, dataQr.content)

        try {
            //imagesToSend
            const body = await fetchArchivoConToken(`v1/CertificadoUpdateQr/${idCertificate}/update/`, imageQrToSend, 'PATCH');
            const resp = await body.json();
    
            if (!body.ok) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: `Dato no Actualizado`,
                })
            } else {
                Swal.fire({
                    icon: 'success',
                    title: 'Actualizacion correcta',
                    text: `Dato Actualizado`,
                })
                dispatch(updateImageQrReport({
                    imageQr: resp.certificado_qr,
                    idReport: idReport,
                }))
            }

        }
        catch (error) {
            console.log(error)
        }
    }

}

const updateImageQrReport = (data) => ({
    type: types.reportImageQRUpdate,
    payload: data,
});


export const startReportSensorUpdate = (sensores) => {
    return async (dispatch) => {

        try {
            const resp = await fetchConToken(`v1/ReportMaquinaFormats/${sensores.id}/update/`, { machine: sensores.medidores }, 'PATCH');
            const body = await resp.json();
            if (body?.id) {
                Swal.fire({
                    icon: 'success',
                    title: 'Actualizacion correcta',
                    text: `Dato Actualizado`,
                })
                dispatch(reportSensorUpdate({ machines: body.machine, id: sensores.id }))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: `Dato no Actualizado`,
                })
            }



        }
        catch (error) {
            console.log(error)
        }

    }
};

export const startReportEquipoUpdate = (equipo) => {
    return async (dispatch) => {

        try {
            const resp = await fetchConToken(`v1/ReportSistemaFormats/${equipo.id}/update/`, equipo, 'PATCH');
            const body = await resp.json();
            if (body?.id) {
                Swal.fire({
                    icon: 'success',
                    title: 'Actualizacion correcta',
                    text: `Dato Actualizado`,
                })
                dispatch(reportEquipoUpdate(body))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: `Dato no Actualizado`,
                })
            }

        }
        catch (error) {
            console.log(error)
        }

    }
};



export const startReportOpcionUpdate = (opcion) => {
    return async (dispatch) => {

        try {
            const resp = await fetchConToken(`v1/ReportOpcionesFormats/${opcion.id}/update/`, opcion, 'PATCH');
            const body = await resp.json();
            if (body?.id) {
                Swal.fire({
                    icon: 'success',
                    title: 'Actualizacion correcta',
                    text: `Dato Actualizado`,
                })
                dispatch(reportOpcionUpdate(body))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: `Dato no Actualizado`,
                })
            }

        }
        catch (error) {
            console.log(error)
        }

    }
};

const reportOpcionUpdate = (opcion) => ({
    type: types.reportOpcionUpdate,
    payload: opcion,
})


export const startUpdateImagesReport = (values) => {
    return async (dispatch) => {
        try {

            if (!values.data.nuevo_autor) {
                return Swal.fire({
                    icon: 'info',
                    title: 'Mismo autor seleccionado',
                    text: `Seleccione uno diferente al anterior`,
                })
            }
            const resp = await fetchConToken(`v1/ReportFormatsAuthor/${values.reporte_id}/`, values.data, 'PUT');
            const body = await resp.json();
            if (body?.id) {
                Swal.fire({
                    icon: 'success',
                    title: 'Actualizacion correcta',
                    text: `Autor actualizado`,
                })
                dispatch(updateImagesReport(body))
            }
        }
        catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Ocurrio un error',
                text: `Error`,
            })
            console.log(error)
        }

    }
};

export const updateImagesReport = (data) => ({
    type: types.reportImagesUpdate,
    payload: data,
});



export const startReportOperacionUpdate = (id) => {
    return async (dispatch, getState) => {
        const { calculateData, CalculateVariablesData } = getState().calculate
        let resultados_pruebas = prepareResultadosPruebas(calculateData)
        let variables_form = CalculateVariablesData
        try {
            const resp = await fetchConToken(`v1/ReportPruebasFormats/${id}/update/`, { pruebas: resultados_pruebas, valores_operaciones: variables_form }, 'PATCH');
            const body = await resp.json()
            if (body?.id) {
                Swal.fire({
                    icon: 'success',
                    title: 'Actualizacion correcta',
                    text: `Prueba Actualizada`,
                })
                dispatch(reportOperacionUpdate({ valores_operaciones: variables_form, pruebas: resultados_pruebas, conclusiones: body.conclusiones }))

            }
        }
        catch (error) {
            console.log(error)
        }

    }
};



const reportOperacionUpdate = (operacion) => ({
    type: types.reportOperacionUpdate,
    payload: operacion,
});


export const startDetailsCertificate = (data) => {
    return async (dispatch) => {

        try {
            if (data) {
                const resp = await fetchURLConToken(data);
                const body = await resp.json();
                if (body?.id) {
                    dispatch(detailsCertificate(body))

                }
            } else {
                dispatch(detailsCertificate(null))
            }

        }
        catch (error) {
            console.log(error)
        }

    }
};
const detailsCertificate = (certificate) => ({
    type: types.detailsCertificate,
    payload: certificate,
});





const reportClienteUpdate = (cliente) => ({
    type: types.reportClienteUpdate,
    payload: cliente,
});

const reportSensorUpdate = (sensor) => ({
    type: types.reportSensorUpdate,
    payload: sensor,
})

const reportEquipoUpdate = (equipo) => ({
    type: types.reportEquipoUpdate,
    payload: equipo,
})

