export const prepareVariable = (variables = []) => {
  /// copiamos los elementos de las variables
  let elements = [...variables]

  //guardamos las posiciones ordenadas
  let positions = elements.map((el) => el.posicion)
  positions.sort((a, b) => a - b)

  //eliminamos los repetidos
  let posNoRep = positions.filter((pos, index) => {
    return positions.indexOf(pos) === index;
  })

  //agrupamos las variables segun su posicion 
  let groupVariable = posNoRep.map((position) => elements.filter((element) => element.posicion == position))
  return groupVariable

}


export const prepareInputValores = (variables = []) => {
  let inputData = new Object()
  variables.map((elemento) => elemento.posicion ? (!elemento.nombre_categoria && elemento.range.length !== 0 ? elemento.range.map((el) => (inputData[el.name] = el.valor_defecto))
    : null) : elemento.valor.map((resultado, index) => inputData[`${index + 1}-${elemento.nombre}-${elemento.identificador_variable}`] = resultado))
  return inputData
}