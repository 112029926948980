import { fetchURLConToken } from "src/helper/fetch";
import { parsear_valores_variables, preparar_elemento_formula, preparar_resultados_pruebas, separar_elementos_formulas } from "src/helper/helperCalculate";
import { prepareInputValores, prepareVariable } from "src/helper/helperVariable";
import { types } from "src/types/types";


export const calculateStartLoading = (seccionValues, variableCategory) => {
    return async (dispatch, getState) => {
        const { inputData: inputValues, calculateData } = getState().calculate
        //const { activeReport } = getState().report

        try {
            let map_secciones_calculo = seccionValues.filter((element) => element.opcion.length === 0)

            // console.log(seccionValues)
            let element_formula = preparar_elemento_formula(map_secciones_calculo)
            let formulas_all = await Promise.all(element_formula.map(async (value) => {
                let variables = []
                let obtener_target_value = value.parametros.map((param) => {
                    let identificador = `${param.nombre_variable}-${param.id}`
                    variables.push({
                        "idOperacion": value.id_operacion,
                        "nombre": param.nombre_variable,
                        "valor": separar_elementos_formulas(identificador, inputValues),
                    })

                    return separar_elementos_formulas(identificador, inputValues)
                })
                // console.log(calculateData)
                let condicion = calculateData.find((element) => element.id_operacion === value.id_operacion)?.condicion;
                let esDesaprobado = condicion ? condicion : false
                //let esDesaprobado = calculateData.includes(value.prueba)
                const body = await fetchURLConToken(value.url_operation + obtener_target_value + ',' + '[' + esDesaprobado + ']' + ']')
                //const body = await fetchURLConToken(value.url_operation + obtener_target_value + ']')
                const res = await body.json()
                // if(value.url_operation==="http://192.168.0.184:8000/api/v1/OperacionGeneralAlineaci%C3%B3nRayosXHazLuminoso/%5B"){
                //     console.log(res.resultado)
                // }
                //console.log( res.resultado.data)
                if (res.resultado) {
                    return {
                        esElementoTabla: value.esElementoTabla,
                        id_operacion: value.id_operacion,
                        id_prueba: value.id_prueba,
                        seccion: value.seccion,
                        prueba: value.prueba,
                        resultado: esDesaprobado ? [{
                            parametros: '',
                            resultado: calculateData.find((element) => element.id_operacion === value.id_operacion).resultado[0].resultado,
                            estado: false
                        }]
                            : res.resultado.data,
                        tolerancia: res.resultado.tolerancia,
                        estado: res.resultado.estado,
                        condicion: esDesaprobado,
                        variables: variables,
                    }
                } else {
                    return {
                        esElementoTabla: false,
                        id_operacion: value.id_operacion,
                        id_prueba: value.id_prueba,
                        seccion: value.seccion,
                        prueba: value.prueba,
                        resultado: [{ parametros: '', resultado: '', estado: '' }],
                        tolerancia: "",
                        estado: "No Aplica",
                        condicion: false,
                        variables: variables,
                    }

                }
            }))
            let filtradoVariables = variableCategory.filter((element) => element.nombre_variable)
            let variables_form = filtradoVariables.map((value) => {
                let identificador = `${value.nombre_variable}-${value.identificador_variable}`
                let input_value = separar_elementos_formulas(identificador, inputValues);
                return {
                    identificador_variable: value.identificador_variable,
                    id: value.id,
                    nombre: value.nombre_variable,
                    valor: parsear_valores_variables(input_value)
                }
            })
            let dataCalculate = {
                variables_form: variables_form,
                formulas_all: formulas_all,
            }
            //formulas_all: isEdit ? preparar_resultados_pruebas(activeReport.pruebas) : formulas_all,
            // console.log(formulas_all,'real')
            dispatch(calculateLoaded(dataCalculate))
        } catch (error) {
            console.log(error)
        }

    }
}

export const calculatefailedTest = (data) => ({
    type: types.calculateFaildTest,
    payload: data
})
export const AddFailTest = (data) => ({
    type: types.AddNewFailTest,
    payload: data
})
export const checkAllTest = (data) => ({
    type: types.calculateCheckAll,
    payload: data
})
export const setObservacion = (value) => ({
    type: types.setInputObservacion,
    payload: value
})
export const clearValueSelected = () => ({
    type: types.clearSelectedCombo
})
export const calculateLoaded = (dataCalculate) => ({
    type: types.calculateLoaded,
    payload: dataCalculate
})
export const updateCalculateData = (value) => ({
    type: types.updateCalculateData,
    payload: value
})


export const clearStateCalculate = () => ({
    type: types.calculateClear
})


export const updateInput = (inputValue) => ({
    type: types.updateInput,
    payload: inputValue,
})
export const parceInputVariable = (variables) => {
    return async (dispatch) => {
        try {
            let inputData = prepareInputValores(variables)
            dispatch(variableInputLoaded(inputData))
        } catch (error) {
            console.log(error)
        }
    }
}


export const parceDataVariable = (variables) => {
    return async (dispatch) => {
        try {
            let variable = prepareVariable(variables)
            dispatch(variableLoaded(variable))
        } catch (error) {
            console.log(error)
        }
    }
}
const variableLoaded = (variable) => ({
    type: types.variableLoaded,
    payload: variable,
})


const variableInputLoaded = (inputData) => ({
    type: types.variableInputLoaded,
    payload: inputData,
})

