import React, { Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { startAuthChecking } from './actions/auth'
import LoaderSpinner from './components/loader/LoaderSpinner'
import { PrivateRoute } from './routers/PrivateRoute'
import { PublicRoute } from './routers/PublicRoute'
import './scss/style.scss'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))


function App() {

  const dispatch = useDispatch()
  const { checking, username } = useSelector((state) => state.auth)
  useEffect(() => {
    dispatch(startAuthChecking());
  }, [])

  if (checking) {
    return <LoaderSpinner />
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<LoaderSpinner />}>
        <Routes>
          <Route
            path="/login"
            name="Login Page"
            element={
              <PublicRoute isAuthenticated={!!username}>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="*"
            name="Home"
            element={
              <PrivateRoute isAuthenticated={!!username}>
                <DefaultLayout />
              </PrivateRoute>
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )

}

export default App
