import { types } from "src/types/types"


const initialState = {
    dataSesiones: [],
    dataUsers: [],
}

export const sesionesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.sesionesLoaded:
            return { ...state, dataSesiones: action.payload }
        case types.getUsers:
            return { ...state, dataUsers: action.payload }
        default:
            return state
    }
}


