import { types } from 'src/types/types'

const initialState = {
  dataNavigation: {
    routes: [], nav: []
  }
}
export const navReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.navRoute:
      return { ...state, dataNavigation: action.payload }
    default:
      return state
  }
}
