import { types } from "src/types/types";

export const startChangeStateSidebar = (state) => {
    return async (dispatch) => {
        dispatch(changeState(state))
    }
}
const changeState = (state) => ({
    type: types.uiChangeStateSidebar,
    payload: state,
})
//Loading

export const startLoading = () => ({
    type: types.uiStartLoading,
})
export const finishLoading = () => ({
    type: types.uiFinishLoading,
})


export const startDisabledButton = () => ({
    type: types.uiStartDisabled,
})
export const finishDisabledButton = () => ({
    type: types.uiFinishDisabled,
})




export const uiOpenModal = () => ({
    type: types.uiOpenModal
})
export const uiCloseModal = () => ({
    type: types.uiCloseModal
})
export const startLoadingRoutes = () => ({
    type: types.uiStartLoadingRoutes,
})

export const finishLoadingRoutes = () => ({
    type: types.uiFinishLoadingRoutes,
})
