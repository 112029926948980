import { types } from "src/types/types"


const initialState = {
    dataSedes: [],
    activeSede: null
}



export const sedesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.sedesLoaded:
            return { ...state, dataSedes: action.payload }
        case types.sedesAddNew:
            return { ...state, dataSedes: [...state.dataSedes, action.payload] }
        case types.sedeSetActive:
            return { ...state, activeSede: action.payload }
        case types.sedesUpdate:
            return { ...state, dataSedes: state.dataSedes.map((el) => el.id === action.payload.id ? { ...action.payload } : el) }
        case types.ClearActiveSede:
            return {
                ...state,
                activeSede: null,
            };
        case types.sedeDeleted:
            return {
                ...state,
                dataSedes: state.dataSedes.filter((el) =>
                    el.id !== action.payload
                ),
            };

        default:
            return state
    }
}
