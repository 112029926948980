import { types } from "src/types/types"


const initialState = {
    dataComponente: [],
    activeComponente: null
}

export const componenteReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.componenteLoaded:
            return { ...state, dataComponente: action.payload }
        case types.componenteAddNew:
            return { ...state, dataComponente: [...state.dataComponente, action.payload] }
        case types.componenteSetActive:
            return { ...state, activeComponente: action.payload }
        case types.componenteUpdate:
            return { ...state, dataComponente: state.dataComponente.map((el) => el.id === action.payload.id ? { ...action.payload } : el) }
        case types.clearActiveComponente:
            return {
                ...state,
                activeComponente: null,
            };
        case types.componenteDeleted:
            return {
                ...state,
                dataComponente: state.dataComponente.filter((el) =>
                    el.id !== action.payload
                ),
            };
        default:
            return state
    }
}


