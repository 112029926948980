import { types } from 'src/types/types'

const initialState = {
  dataOrganizacion: [],
  dataRuc: {
    ruc: '',
    razon_social: '',
    nombre_comercial: '',
    direccion_legal: '',
    pais_organizacion: '',
    departamento_organizacion: '',
    provincia_organizacion: '',
    distrito_organizacion: '',
    tipo: "",
    ciiu: "",
    is_enabled: true,
    members_ids: []
  },
  dataDepartment: [],
  dataProvincia: [],
  dataDistrito: [],
  activeOrganizacion: null
}

export const organizacionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.organizacionLoaded:
      return { ...state, dataOrganizacion: action.payload }
    case types.dataRucLoaded:
      return { ...state, dataRuc: { ...state.dataRuc, ...action.payload } }
    case types.organizacionActive:
      return {
        ...state, activeOrganizacion: action.payload
      }
    case types.dataDepartamentoLoaded:
      return { ...state, dataDepartment: action.payload }
    case types.dataProvinciaLoaded:
      return action.payload === 'NO DATA'
        ? { ...state, dataProvincia: [], dataDistrito: [] }
        : { ...state, dataProvincia: action.payload }
    case types.dataDistritoLoaded:
      return action.payload === 'NO DATA'
        ? { ...state, dataDistrito: [] }
        : { ...state, dataDistrito: action.payload }
    case types.organizacionAddNew:
      return {
        ...state,
        dataOrganizacion: [...state.dataOrganizacion, action.payload],
        dataRuc: initialState.dataRuc,
      }
    case types.organizacionUpdate:
      return {
        ...state,
        dataOrganizacion: state.dataOrganizacion.map((el) => (el.id === action.payload.id ? { ...action.payload } : el)),
      }
    case types.clearActiveOrganizacion:
      return {
        ...state,
        activeOrganizacion: null
      }

    case types.organizacionDeleted:
      return {
        ...state,
        dataOrganizacion: state.dataOrganizacion.filter((el) =>
          el.id !== action.payload
        ),
      };
    default:
      return state
  }
}
