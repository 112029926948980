import { types } from "src/types/types"

const initialState = {
    dataMedidor: [],
    activeMedidor: null
}

export const medidorReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.medidorLoaded:
            return { ...state, dataMedidor: action.payload }
        case types.medidorAddNew:
            return { ...state, dataMedidor: [...state.dataMedidor, action.payload] }
        case types.medidorSetActive:
            return { ...state, activeMedidor: action.payload }
        case types.medidorUpdate:
            return { ...state, dataMedidor: state.dataMedidor.map((el) => el.id === action.payload.id ? { ...action.payload } : el) }
        case types.clearActiveMedidor:
            return {
                ...state,
                activeMedidor: null,
            };
        case types.medidorDeleted:
            return {
                ...state,
                dataMedidor: state.dataMedidor.filter((el) =>
                    el.id !== action.payload
                ),
            };

        default:
            return state
    }

}



