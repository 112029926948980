import Swal from "sweetalert2";
import { fetchURLConToken, fetchURLSinToken } from "./fetch";

export const getUserLocation = async () => {

    try {
        const response = await fetchURLSinToken('https://api.ipify.org?format=json');
        const body = await response.json()
        if (body?.ip) {
            return new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(
                    ({ coords }) => {
                        resolve(
                            {
                                ip_dispositivo: body.ip,
                                latitud: coords.latitude.toString(),
                                longitud: coords.longitude.toString(),
                                tipo_dispositivo: navigator?.userAgentData?.platform,
                            }
                        )
                    }, (err) => {
                        console.log('No se pudo obtener la geolocalizacion', err)
                        Swal.fire('Error!', 'Geolocalizacion no aceptada', 'error')
                        reject()
                    }
                )
            })
        }

    } catch (error) {
        console.error(error);
    }

}

export const getUserAgent = () => {
    return { internetActive: navigator.onLine }
}