export const types = {
    uiChangeStateSidebar: '[UI] Set change Visibility and Unfoldable Sidebar',
    uiStartLoading: '[UI] Start loading',
    uiFinishLoading: '[UI] Finish loading',
    uiOpenModal: '[UI] Open modal',
    uiCloseModal: '[UI] Close modal',
    uiStartDisabled: '[UI] start DISABLED BUTTON',
    uiFinishDisabled: '[UI] finish DISABLED BUTTON',
    uiStartLoadingRoutes: '[UI] Star loading routes',
    uiFinishLoadingRoutes: '[UI] Finish loading routes',
    authStartLogin: '[auth] Start login',
    authLogin: '[auth] Login',
    authLogout: '[auth] Logout',
    authChecking: '[auth] Checking login state',
    authCheckingFinish: '[auth] Finish checking login state',
    navRoute: '[nav] generate nav for route',
    userSubmitData: '[registro] send data in page client',
    clientsLoaded: '[registro] Data clients loaded Loaded',
    clientSetActive: '[registro] Data client set Active ',
    localSetActive: '[registro] Data local set Active ',
    devicesLoaded: '[registro] system Data Loaded',
    systemSetActive: '[registro] system set Active',
    componentSetActive: '[registro] component set Active',
    registerClient: '[registro] register client values',
    registerSystem: '[registro] register System values ',
    registerSensor: '[registro] register sensor values',
    saveReportData: '[registro] save data report',
    updateCheckboxValues: '[registro] update checkbox values',
    loadCheckboxValues: '[registro] load default values',
    cleanUser: '[registro] clean user state',
    ambienteLoaded: '[registro] register ambiente values',
    ambienteSetActive: '[registro] ambiente set active',
    sensorLoaded: '[registro] sensor Data Loaded',
    sensorSetActive: '[registro] sensor set active',
    registerImages: '[registro] save images',
    variableLoaded: '[calculate] variable parse loaded',
    clearSelectedCombo: '[calculate] clear select combo',
    variableInputLoaded: '[calculate] input variable loaded',
    updateCalculateData: '[calculate] update state CalculateData',
    updateInput: '[calculate] update input',
    calculateLoaded: '[calculate] calculate data loaded',
    calculateClear: '[calculate] clear state calculate',
    calculateFaildTest: '[calculate] failed test',
    calculateCheckAll: '[calculate] control check all',
    AddNewFailTest: '[calculate] add new Fail test',
    setInputObservacion: '[calculate] set input observacion',
    dataRucLoaded: '[organizacion] get data ruc',
    dataDepartamentoLoaded: '[organizacion] data department loaded',
    dataProvinciaLoaded: '[organizacion] data provincia loaded',
    dataDistritoLoaded: '[organizacion] data distrito loaded',
    organizacionAddNew: '[organizacion] create organizacion',
    organizacionLoaded: '[organizacion] organizacion loaded data',
    organizacionActive: '[organizacion] organizacion active',
    organizacionUpdate: '[organizacion] organizacion update data',
    organizacionDeleted: '[organizacion] delete data  organizacion',
    clearActiveOrganizacion: '[organizacion] clear active organizacion',
    sedesLoaded: '[sedes] sedes loaded data',
    sedesAddNew: '[sedes] add new sedes',
    sedeSetActive: '[sedes] active sede edit',
    sedesUpdate: '[sedes] update sedes ',
    ClearActiveSede: '[sedes] clear selected sede',
    sedeDeleted: '[sedes] detele row sede',
    areaLoaded: '[area] areas loaded data',
    areaSetActive: '[areas] area set active',
    ClearActiveArea: '[area] clear active data area',
    areaAddNew: '[area] register new area',
    areaUpdate: '[area] update selected area',
    areaDeleted: '[area] delete row area',
    contactosLoaded: '[contactos] contactos loaded data',
    contactoSetActive: '[contacto] contacto set active',
    contactoAddNew: '[contacto] contacto register',
    contactoUpdate: '[contacto] contacto set update',
    clearActiveContacto: '[contacto] clear active contacto',
    contactoDeleted: '[contacto] delete row contacto',
    medidorLoaded: '[medidor] medidor loaded',
    medidorAddNew: '[medidor] medidor add New',
    medidorSetActive: '[medidor] medidor set active',
    medidorUpdate: '[medidor] update medidor',
    clearActiveMedidor: '[medidor] active medidor',
    medidorDeleted: '[medidor] delete row medidor',
    calibracionLoaded: '[calibration] calibration loaded',
    calibracionAddNew: '[calibration] calibration add new',
    calibracionSetActive: '[calibration] selected calibration',
    calibracionUpdate: '[calibration] update calibration',
    calibracionDeleted: '[calibration] delete row calibration',
    clearActiveCalibracion: '[calibration] clear  active calibration',
    sistemaLoaded: '[sistema] data sistema loaded',
    sistemaAddNew: '[sistema] register new  sistema',
    sistemaSetActive: '[sistema] edit sistema',
    sistemaUpdate: '[sistema] update sistema',
    clearActiveSistema: '[sistema] clear edit sistema',
    sistemaDeleted: '[sistema] delete sistema selected',
    componenteLoaded: '[componente] loaded all data componente',
    componenteAddNew: '[componente] register new componente',
    componenteSetActive: '[componente] active componente',
    componenteUpdate: '[componente] update componente',
    componenteDeleted: '[componente] delete componente',
    clearActiveComponente: '[componente] celar edit componente',
    reportsLoaded: '[report] report data loader',
    addReport: '[report] add new report ',
    reportClienteUpdate: '[report] update data report cliente',
    reportSensorUpdate: '[report] update data  report sensor',
    reportEquipoUpdate: '[report] update data report devices',
    reportSetActive: '[report] active report',
    certificateUpdate: '[report] certificate update',
    reportOperacionUpdate: '[report]  operacion update',
    reportOpcionUpdate: '[report] opcion update',
    statisticalLoaded: '[report] loaded data statistical',
    sesionesLoaded: '[sesion] loaded data sesiones',
    getUsers: '[sesion] get all users ',
    sessionTime: '[time] set remaining time',
    detailsCertificate: '[report] get details certificate',
    reportImagesUpdate: '[report] update  firmas images report',
    reportImagesCSUpdate: '[report] update images componente and sistema',
    reportImageQRUpdate:'[report] update image QR',
}
