export const prepareDataSistema = (dataSistema = []) => {
    return dataSistema.map((el) => ({
        sistema_id: el.id.toString(),
        sistema_titulo: el.title,
        sistema_marca: el.marca,
        sistema_modelo: el.modelo,
        sistema_nombre: el.name_option,
        sistema_antiguedad: el.antiguedad,
        sistema_instalacion: el.year_instalacion,
        sistema_miembros: el.members.map((member) => ({
            componente_id: member.id.toString(),
            componente_titulo: member.title,
            componente_marca: member.marca,
            componente_modelo: member.modelo,
            componente_nombre: member.name_option,
            componente_antiguedad: member.antiguedad,
            componente_instalacion: member.year_instalacion,
        }))
    }))

}


export const prepareDataMedidor = (dataMedidor = []) => {
    return dataMedidor.map((el) => ({
        medidor_id: el.id.toString(),
        title: el.title,
        medidor_marca: el.marca,
        medidor_nombre: el.full_name,
        medidor_modelo: el.modelo,
        medidor_serie: el.serie,
        medidor_miembros: el.members.map((member) => ({
            medidor_calibracion_id: member.id.toString(),
            fecha_calibracion: member.fecha_calibracion,
        })),
        fecha_calibracion: "",
        fecha_vencimiento: "",
    }))

}

export const prepareDataReport = (dataReport) => {

    return {
        ...dataReport,
        sistema: {
            ...dataReport.sistema,
            sistema_titulo: dataReport.sistema?.sistema_titulo ? dataReport.sistema.sistema_titulo : "",
            sistema_antiguedad: dataReport.sistema.sistema_antiguedad ? dataReport.sistema.sistema_antiguedad : "",
            sistema_año_instalacion: dataReport.sistema.sistema_año_instalacion ? dataReport.sistema.sistema_año_instalacion : "",
        },
        componente: {
            ...dataReport.componente,
            tubo_titulo: dataReport.componente?.tubo_titulo ? dataReport.componente.tubo_titulo : "",
            tubo_antiguedad: dataReport.componente.tubo_antiguedad ? dataReport.componente.tubo_antiguedad : "",
            tubo_año_instalacion: dataReport.componente.tubo_año_instalacion ? dataReport.componente.tubo_año_instalacion : "",
        }
    }
}