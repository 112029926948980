import { types } from 'src/types/types'

const initialState = {
  contactosData: [],
  activeContacto: null,

}
export const contactoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.contactosLoaded:
      return { ...state, contactosData: action.payload }
    case types.contactoSetActive:
      return { ...state, activeContacto: action.payload }
    case types.contactoUpdate:
      return { ...state, contactosData: state.contactosData.map((el) => el.id === action.payload.id ? { ...action.payload } : el) }
    case types.contactoAddNew:
      return { ...state, contactosData: [...state.contactosData, action.payload] }
    case types.clearActiveContacto:
      return { ...state, activeContacto: null }
    case types.contactoDeleted:
      return {
        ...state,
        contactosData: state.contactosData.filter((el) =>
          el.id !== action.payload
        ),
      };
    default:
      return state
  }
}
