
export const preparar_elemento_formula = (map_secciones_calculo = []) => {
    let element_formula = [];

    map_secciones_calculo.map((seccion) => (seccion.calculo.length > 0 ? (seccion.calculo) : (seccion.table)).map((calculo) => calculo.operacion.map((op) => {
        return element_formula.push({
            id_prueba: calculo.id,
            id_operacion: op.id,
            esElementoTabla: seccion.table.length > 0,
            seccion: seccion.secciones_titulo,
            prueba: op.operacion_titulo,
            calculo: seccion.secciones_titulo + '-' + calculo.pruebas_titulo,
            url_operation: op.operation_url,
            parametros: op.variables
        })
    })))

    return element_formula
    //  prueba: op.operacion_titulo,
    //    prueba: calculo.pruebas_titulo,
}

export const preparar_resultados_pruebas = (pruebas) => {
    let calculateData = []
    pruebas[1].map(el => calculateData.push({
        condicion: el[0].resultados[0].resultados.resultado.condicion,
        estado: el[0].resultados[0].resultados.resultado.estado,
        id_operacion: el[0].resultados[0].idOperacion,
        prueba: el[0].resultados[0].prueba,
        resultado: el[0].resultados[0].resultados.resultado.data,
        seccion: el[0].resultados[0].seccion,
        tolerancia: el[0].resultados[0].resultados.resultado.tolerancia,
        variables: el[0].resultados[0].variables,
        condicion: el[0].resultados[0].resultados.resultado.condicion,
    }))
    // pruebas[1].map(el => el[0].resultados[0].resultados.resultado.estado === false && array.push(el[0].resultados[0].prueba))
    // console.log(array)
    //pruebas[1].map(el => el[0].resultados[0].resultados.resultado.condicion === true && array.push(el[0].resultados[0].prueba))
    return calculateData
}
export const preparar_accordion_item = (map_secciones = []) => {
    let element_formula = [];
    map_secciones.map((seccion) => {
        return element_formula.push({
            id: seccion.id,
            calculo: seccion.calculo.length > 0 ? (seccion.calculo) : (seccion.table),
            seccion: seccion.secciones_titulo,
        })
    })

    return element_formula
}

export const preparar_accordion_calculo = (itemCalculo = []) => {
    let elementos_calculo = [];
    itemCalculo.map((calculo) => calculo.operacion.map((op) => {
        return elementos_calculo.push({
            pruebaID: calculo.id,
            pruebaNombre: calculo.pruebas_titulo,
            operacionID: op.id,
            operacionNombre: op.operacion_titulo,
        })
    }))
    return elementos_calculo


}


export const separar_elementos_formulas = (identificador, inputValues) => {

    let elementos = Object.keys(inputValues)
    let elementosFiltrado = elementos.filter((item) => item.slice(2) === identificador.toString())
    let separadores = elementosFiltrado.map((value) => {
        let nombre_variable = value
        if (!parseFloat(inputValues[value])) {
            return "{" + '"' + nombre_variable + '":' + '"' + inputValues[value] + '"' + "}"
        }
        else {
            return "{" + '"' + nombre_variable + '":' + parseFloat(inputValues[value]).toString() + "}"
        }
    })

    let parse_separadores = separadores.map((value) => {

        return JSON.parse(value)
    })
    let keys = parse_separadores.flatMap((value) => {

        return Object.keys(value)
    })
    let outputArray = Array.from(new Set(keys))

    let result = outputArray.map((value) => {
        let parceInputValue = isNaN(inputValues[value]) ? `"${inputValues[value]}"` : inputValues[value]
        return parceInputValue
    })

    let resulfinal = ("[" + result + "]").toString()
    return resulfinal
}



export const parsear_valores_variables = (input_value) => {
    let eliminarCorchetes = input_value.slice(1, -1)
    let obtenerValues = eliminarCorchetes.split(',')
    let resultado = obtenerValues.map(el => isNaN(el) ? el.slice(1, -1) : el)
    return resultado
}