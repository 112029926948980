import { fetchArchivoConToken, fetchConToken } from 'src/helper/fetch'
import { prepareDataCliente } from 'src/helper/helperClients'
import { getUserAgent } from 'src/helper/helperDataUserAgent'
import { prepareDataMedidor, prepareDataSistema } from 'src/helper/helperDevices'
import { prepareResultadosPruebas, prepareValuesImages } from 'src/helper/helperRegister'
import { types } from 'src/types/types'
import Swal from 'sweetalert2'
import { clearValueSelected, parceInputVariable } from './calculate'
import { addNewReport, reportStartLoading } from './reports'
import { finishDisabledButton, startDisabledButton } from './ui'

export const ClientSetActive = (idClient) => ({
  type: types.clientSetActive,
  payload: idClient,
})
export const localSetActive = (idInstallation) => ({
  type: types.localSetActive,
  payload: idInstallation,
})
export const componentSetActive = (data) => ({
  type: types.componentSetActive,
  payload: data,
})
export const systemSetActive = (data) => ({
  type: types.systemSetActive,
  payload: data,
})
export const sensorSetActive = (data) => ({
  type: types.sensorSetActive,
  payload: data,
})

export const dataClientLoading = () => {
  return async (dispatch) => {
    try {
      const resp = await fetchConToken('v2/Customer/Organizaciones/')
      const data = await resp.json()
      if (data?.length > 0) {
        const dataCliente = prepareDataCliente(data)
        dispatch(clientsLoaded(dataCliente))
      }

    } catch (error) {
      console.log(error)
    }
  }
}

export const AmbienteSetActive = (data) => ({
  type: types.ambienteSetActive,
  payload: data,
})

export const registerClientValues = (values) => ({
  type: types.registerClient,
  payload: values,
})
export const registerSystemValues = (values) => ({
  type: types.registerSystem,
  payload: values,
})
export const updateCheckboxValues = (values) => ({
  type: types.updateCheckboxValues,
  payload: values,
})
export const loadCheckboxValues = (values) => ({
  type: types.loadCheckboxValues,
  payload: values,
})
export const registerImagesValues = (values) => ({
  type: types.registerImages,
  payload: values,
})
const clientsLoaded = (data) => ({
  type: types.clientsLoaded,
  payload: data,
})

export const dataSystemLoading = () => {
  return async (dispatch) => {
    try {
      const resp = await fetchConToken('v1/Sistemas/')
      const body = await resp.json()
      if (body?.length > 0) {
        const data_sistema = prepareDataSistema(body)
        dispatch(systemsLoaded(data_sistema))
      }

    } catch (error) {
      console.log(error)
    }
  }
}

export const cleanUserState = () => ({
  type: types.cleanUser,
})
export const dataSensorLoading = () => {
  return async (dispatch) => {
    try {
      const resp = await fetchConToken('v1/Medidores/')
      const body = await resp.json()
      if (body.length > 0) {
        const data_medidor = prepareDataMedidor(body)
        dispatch(sensorLoaded(data_medidor))
      }

    } catch (error) {
      console.log(error)
    }
  }
}



export const StartAddNewReport = (defaultValuesCheckbox, fechaCalidad, nombre_reporte, formato_id, codigo_formato, variablesIniciales) => {
  return async (dispatch, getState) => {

    dispatch(startDisabledButton())
    const { dataReports } = getState().report
    const { activeClient, activeDevice, activeImages, dataCheckbox } = getState().registro
    const { calculateData, CalculateVariablesData, inputData } = getState().calculate
    const { imgOne, imgTwo, imgThree, observacion, name_img_1, name_img_2, name_img_3, imgFour } = activeImages
    const { internetActive } = getUserAgent()
    if (!internetActive) {
      let variables_registro = {
        data_id: codigo_formato,
        data_pag: 'GLOBAL',
        data_client: activeClient,
        data_device: activeDevice,
        data_input_variables: inputData,
        data_inspection: dataCheckbox,
        data_fecha: fechaCalidad,
        data_img: {
          observacion: observacion,
          name_img_1: name_img_1,
          name_img_2: name_img_2,
          name_img_3: name_img_3,
          imgOne: imgOne,
          imgTwo: imgTwo,
          imgThree: imgThree,
          imgFour: imgFour
        }
      }
      const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
        JSON.stringify(variables_registro)
      )}`;
      const link = document.createElement("a");
      link.href = jsonString;
      let today = new Date();
      let now = today.toLocaleString();
      link.download = `${activeClient?.cliente_razon_social}_${nombre_reporte}_GLOBAL_${now}.json`;
      link.click();
      dispatch(finishDisabledButton())
      dispatch(cleanUserState())
      dispatch(clearValueSelected())
      //limpia las variables a su valor inicial
      dispatch(parceInputVariable(variablesIniciales))
      //limpia los checkbox
      dispatch(loadCheckboxValues(defaultValuesCheckbox));
      return Swal.fire({
        icon: 'info',
        title: 'Sin internet',
        text: 'Archivo de recuperacion generado',
      })

    }
    //VARIABLES DE LA PAGINA DE CLIENTES
    const {
      cliente_razon_social,
      cliente_ruc,
      cliente_direccion_legal,
      cliente_provincia,
      cliente_departamento,
      cliente_distrito,
      sede_direccion,
      sede_distrito,
      sede_provincia,
      sede_departamento,
      area_nombre,
      cliente_nro_ot,
      sede_contacto,
    } = activeClient

    //VARIABLES de los equipos
    const {
      sistema_titulo,
      sistema_marca,
      sistema_modelo,
      sistema_serie,
      sistema_tension_maxima,
      sistema_corriente_carga_maxima,
      sistema_antiguedad,
      sistema_instalacion,
      componente_titulo,
      componente_marca,
      componente_modelo,
      componente_serie,
      componente_tension_maxima,
      componente_corriente_carga_maxima,
      componente_antiguedad,
      componente_instalacion,
      tipo_rayos_x,
      medidores_activos
    } = activeDevice

    //VARIABLES A ENVIAR AL ENDPOINT DE FORMATOS
    let identificador_reporte = nombre_reporte
    let orden_trabajo = cliente_nro_ot

    let parse_datos_del_solicitante = {
      razon_social: cliente_razon_social,
      ruc: cliente_ruc,
      direccion: cliente_direccion_legal,
      distrito: cliente_distrito,
      provincia: cliente_provincia,
      region: cliente_departamento,
      contactos: sede_contacto,
      instalacion_direccion: sede_direccion,
      instalacion_distrito: sede_distrito,
      instalacion_provincia: sede_provincia,
      instalacion_region: sede_departamento,
      instalacion_ambiente: area_nombre,
    }

    let parse_datos_equipo = {
      sistema_titulo: sistema_titulo,
      sistema_marca: sistema_marca,
      sistema_modelo: sistema_modelo,
      sistema_serie: sistema_serie,
      sistema_tension_maxima: sistema_tension_maxima,
      sistema_corriente_carga_maxima: sistema_corriente_carga_maxima,
      sistema_antiguedad: sistema_antiguedad,
      sistema_año_instalacion: sistema_instalacion,
      tipo_rayos_x: tipo_rayos_x,
    }
    let parse_datos_componentes = {
      tubo_titulo: componente_titulo,
      tubo_marca: componente_marca,
      tubo_modelo: componente_modelo,
      tubo_serie: componente_serie,
      tubo_tension_maxima: componente_tension_maxima,
      tubo_corriente_carga_maxima: componente_corriente_carga_maxima,
      tubo_antiguedad: componente_antiguedad,
      tubo_año_instalacion: componente_instalacion,
    }



    let opciones_value = dataCheckbox
    let resultados_pruebas = prepareResultadosPruebas(calculateData)
    let variables_form = CalculateVariablesData
    let formData = new FormData()
    formData.append('nombre_reporte', identificador_reporte)
    formData.append('numero_de_ot', orden_trabajo)
    formData.append('fecha_control_calidad', fechaCalidad)
    formData.append('observacion', observacion)
    formData.append('datos_del_cliente', JSON.stringify(parse_datos_del_solicitante))
    formData.append('sistema', JSON.stringify(parse_datos_equipo))
    formData.append('componente', JSON.stringify(parse_datos_componentes))
    formData.append('machine', JSON.stringify(medidores_activos))
    formData.append('valores_operaciones', JSON.stringify(variables_form))
    formData.append('pruebas', JSON.stringify([opciones_value, resultados_pruebas]))
    formData.append('formato_id', formato_id)
    if (imgOne) {
      let image1_parse = prepareValuesImages(imgOne)
      formData.append('image_1', image1_parse)
      formData.append('title_image_1', name_img_1)
    }
    if (imgTwo) {
      let image2_parse = prepareValuesImages(imgTwo)
      formData.append('image_2', image2_parse)
      formData.append('title_image_2', name_img_2)
    }
    if (imgThree) {
      let image3_parse = prepareValuesImages(imgThree)
      formData.append('image_3', image3_parse)
      formData.append('title_image_3', name_img_3)
    }
    if (imgFour) {
      let image4_parse = prepareValuesImages(imgFour)
      formData.append('image_extra', image4_parse)
    }


    try {
      const resp = await fetchArchivoConToken('v2/Report/ReportFormats/', formData, 'POST')
      const data = await resp.json()

      if (data.id) {
        dispatch(cleanUserState())
        //limpia los valores del checkbox
        dispatch(loadCheckboxValues(defaultValuesCheckbox));
        dispatch(clearValueSelected())
        if (dataReports.length === 0) {
          dispatch(reportStartLoading({}))
        } else {
          dispatch(addNewReport([data]))
        }
        dispatch(finishDisabledButton())
        Swal.fire({
          title: `Reporte creado con id ${data.id}`,
          text: `Registrado con nombre ${data.nombre_reporte}/${data.numero_de_ot}`,
          icon: 'success',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Copiar #OT del reporte'
        }).then((result) => {
          if (result.isConfirmed) {
            navigator.clipboard.writeText(data.numero_de_ot);
          }
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Reporte no creado',
          text: 'error',
        })
      }

    } catch (error) {
      dispatch(finishDisabledButton())
      console.log(error)
    }
  }
}

export const updateDataForm = (data) => ({ type: types.saveReportData, payload: data })
const sensorLoaded = (data) => ({
  type: types.sensorLoaded,
  payload: data,
})

const systemsLoaded = (data) => ({
  type: types.devicesLoaded,
  payload: data,
})
