import { types } from 'src/types/types'
const initialState = {
  dataClient: [],
  dataSensor: [],
  dataDevice: [],
  dataCheckbox: [],
  activeClient: {
    cliente_id: "0",
    cliente_ruc: "",
    cliente_razon_social: "",
    cliente_nombre_comercial: "",
    cliente_direccion_legal: "",
    cliente_pais: "",
    cliente_direccion_completa: "",
    cliente_departamento: "",
    cliente_provincia: "",
    cliente_distrito: "",
    cliente_nro_ot: "",
    cliente_miembros: [],
    sede_nombre: "",
    sede_id: "0",
    sede_direccion: "",
    sede_pais: "",
    sede_departamento: "",
    sede_provincia: "",
    sede_distrito: "",
    sede_miembros: [],
    sede_contacto: [],
    area_id: "0",
    area_nombre: "",
  },
  activeDevice: {
    sistema_id: "0",
    sistema_titulo: "",
    sistema_marca: "",
    sistema_modelo: "",
    sistema_nombre: "",
    sistema_antiguedad: "",
    sistema_instalacion: "",
    sistema_serie: "",
    sistema_tension_maxima: "",
    sistema_corriente_carga_maxima: "",
    sistema_miembros: [],
    componente_id: "0",
    componente_titulo: "",
    componente_marca: "",
    componente_modelo: "",
    componente_nombre: "",
    componente_antiguedad: "",
    componente_instalacion: "",
    componente_serie: "",
    componente_tension_maxima: "",
    componente_corriente_carga_maxima: "",
    tipo_rayos_x: "default",
    medidores_activos: [],
  },
  activeImages: {
    imgOne: null,
    imgTwo: null,
    imgThree: null,
    imgFour: null,
    name_img_1: '',
    name_img_2: '',
    name_img_3: '',
    observacion: '',
  },
  sede: {
    sede_nombre: "",
    sede_id: "0",
    sede_direccion: "",
    sede_pais: "",
    sede_departamento: "",
    sede_provincia: "",
    sede_distrito: "",
    sede_miembros: [],
    sede_contacto: [],
    area_id: "",
    area_nombre: "",
  },
  area: {
    area_id: "0",
    area_nombre: "",
  },
  cliente: {
    cliente_id: "0",
    cliente_ruc: "",
    cliente_razon_social: "",
    cliente_nombre_comercial: "",
    cliente_direccion_legal: "",
    cliente_pais: "",
    cliente_direccion_completa: "",
    cliente_departamento: "",
    cliente_provincia: "",
    cliente_distrito: "",
    cliente_miembros: []
  },
  sistema: {
    sistema_id: "0",
    sistema_titulo: "",
    sistema_nombre: "",
    sistema_marca: "",
    sistema_modelo: "",
    sistema_antiguedad: "",
    sistema_instalacion: "",
    sistema_serie: "",
    sistema_tension_maxima: "",
    sistema_miembros: [],
    sistema_corriente_carga_maxima: "",
    componente_id: "0",
    componente_titulo: "",
    componente_nombre: "",
    componente_marca: "",
    componente_modelo: "",
    componente_antiguedad: "",
    componente_instalacion: "",
    componente_serie: "",
    componente_tension_maxima: "",
    componente_corriente_carga_maxima: "",
  },
  componente: {
    componente_id: "0",
    componente_titulo: "",
    componente_marca: "",
    componente_modelo: "",
    componente_nombre: "",
    componente_antiguedad: "",
    componente_instalacion: "",
    componente_serie: "",
    componente_tension_maxima: "",
    componente_corriente_carga_maxima: "",
  },
  sensor: {
    medidor_id: "0",
    title: "",
    medidor_marca: "",
    medidor_nombre: "",
    medidor_modelo: "",
    medidor_serie: "",
    medidor_miembros: [],
    fecha_calibracion: "",
    fecha_vencimiento: "",
  },
}
export const registroReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.clientsLoaded:
      return {
        ...state,
        dataClient: action.payload,
      }
    case types.clientSetActive:
      let cliente = action.payload === "0" ? initialState.activeClient : { ...state.dataClient.find((el) => el.cliente_id === action.payload) }
      return {
        ...state,
        activeClient: { ...state.activeClient, ...cliente }
      }

    case types.localSetActive:
      let sede = action.payload === "0" ? initialState.sede : state.activeClient.cliente_miembros.find((el) => el.sede_id === action.payload)
      return {
        ...state,
        activeClient: { ...state.activeClient, ...sede },
      }

    case types.ambienteSetActive:
      let area = action.payload === "0" ? initialState.area : state.activeClient.sede_miembros.find((el) => el.area_id === action.payload)
      return {
        ...state,
        activeClient: { ...state.activeClient, ...area }
      }
    case types.registerClient:
      return {
        ...state,
        activeClient: { ...state.activeClient, ...action.payload }
      }
    case types.devicesLoaded:
      return {
        ...state,
        dataDevice: action.payload,
      }
    case types.systemSetActive:
      let sistema = action.payload === "0" ? initialState.sistema : { ...state.dataDevice.find((el) => el.sistema_id.toString() === action.payload) }
      return {
        ...state,
        activeDevice: { ...state.activeDevice, ...sistema }
      }
    case types.componentSetActive:
      let component = action.payload === "0" ? initialState.componente : state.activeDevice.sistema_miembros.find((el) => el.componente_id === action.payload)
      return {
        ...state,
        activeDevice: { ...state.activeDevice, ...component },
      }
    case types.sensorSetActive:
 
      return {
        ...state,
        activeDevice: { ...state.activeDevice, medidores_activos: [...action.payload] }
      }
    case types.sensorLoaded:
      return {
        ...state,
        dataSensor: action.payload,
      }
    case types.registerSystem:
      return {
        ...state,
        activeDevice: { ...state.activeDevice, ...action.payload },
      }
    case types.registerImages:
      return {
        ...state,
        activeImages: { ...state.activeImages, ...action.payload }
      }
    case types.registerSensor:
      return {
        ...state,
        stateSensor: action.payload,
      }
    case types.updateCheckboxValues:
      let opcionesActualizadas = state.dataCheckbox.map(el => el.opcion === action.payload.opcion ? action.payload : el)
      return {
        ...state,
        dataCheckbox: opcionesActualizadas,
      }
    case types.loadCheckboxValues:
      return {
        ...state,
        dataCheckbox: action.payload,
      }
    case types.saveReportData:
      const { data_client, data_device, data_img } = action.payload
      return {
        ...state,
        activeClient: data_client,
        activeDevice: data_device,
        activeImages: data_img
      }
    case types.cleanUser:
      return {
        ...state,
        activeClient: initialState.activeClient,
        activeDevice: initialState.activeDevice,
      }
    default:
      return state
  }
}
